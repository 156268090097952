import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

import Header from "./header";
import Footer from "./footer";
import "../style/main.scss";

const Layout = ({ children }) => (
    <>
        <Helmet>
            <title>Tissage | 84220 Goult, France | Matière Première l'Atelier</title>
        </Helmet>
        <Header />
        <div>{children}</div>
        <Footer />
    </>
);

Layout.propTypes = {
    children: PropTypes.node.isRequired
};

export default Layout;
