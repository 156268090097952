import _ from "lodash";
import { Link } from "gatsby";
import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby";

export default class Header extends Component {
    state = {
        expanded: false
    };

    componentDidMount() {
        document.body.classList.remove("overflow-hidden");
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.expanded) {
            document.body.classList.add("overflow-hidden");
        } else {
            document.body.classList.remove("overflow-hidden");
        }
    }

    render() {
        return (
            <StaticQuery
                query={pagesQuery}
                render={data => {
                    return (
                        <div className="container">
                            <header className="header padding-24-mobile padding-24-tablet padding-top-64-widescreen padding-bottom-64-widescreen">
                                <nav className="header-links">
                                    {data.allContentfulPage.edges
                                        .sort(
                                            (a, b) =>
                                                a.node.order - b.node.order
                                        )
                                        .map((edge, i) => (
                                            <Link
                                                key={edge.node.path}
                                                to={edge.node.path}
                                                className={`header-link ${i && "is-widescreen"}`}
                                            >
                                                {edge.node.title}
                                            </Link>
                                        ))}
                                    <Link
                                        to="/coupdoeil"
                                        className="header-link is-widescreen"
                                    >
                                        COUP D'OEIL
                                    </Link>
                                    <a
                                        href="https://www.etsy.com/fr/shop/MatPremierelAtelier"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="header-link is-widescreen"
                                    >
                                        Boutique
                                    </a>
                                    <div className="is-tablet">
                                        <div
                                            className="is-flex padding-8"
                                            onClick={() =>
                                                this.setState({
                                                    expanded: true
                                                })
                                            }
                                        >
                                            <i className="menu-icon fas fa-2x fa-bars" />
                                        </div>

                                        {this.state.expanded && (
                                            <div className="menu-expanded is-flex">
                                                <i
                                                    className="menu-icon fas fa-2x fa-window-close"
                                                    onClick={() =>
                                                        this.setState({
                                                            expanded: false
                                                        })
                                                    }
                                                />
                                                {data.allContentfulPage.edges
                                                    .sort(
                                                        (a, b) =>
                                                            a.node.order >
                                                            b.node.order
                                                    )
                                                    .map((edge, i) => (
                                                        <Link
                                                            key={edge.node.path}
                                                            to={edge.node.path}
                                                            className={`header-link padding-left-24 margin-top-32 ${!i && "home-link"}`}
                                                            onClick={() =>
                                                                this.setState({
                                                                    expanded: false
                                                                })
                                                            }
                                                        >
                                                            {edge.node.title}
                                                        </Link>
                                                    ))}
                                                <Link
                                                    to="/coupdoeil"
                                                    className="padding-left-24 header-link margin-top-32"
                                                    onClick={() =>
                                                        this.setState({
                                                            expanded: false
                                                        })
                                                    }
                                                >
                                                    COUP D'OEIL
                                                </Link>
                                                <a
                                                    href="https://www.etsy.com/fr/shop/MatPremierelAtelier"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="padding-left-24 header-link margin-top-32"
                                                    onClick={() =>
                                                        this.setState({
                                                            expanded: false
                                                        })
                                                    }
                                                >
                                                    Boutique
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                </nav>
                            </header>
                        </div>
                    );
                }}
            />
        );
    }
}

const pagesQuery = graphql`
    {
        allContentfulPage {
            edges {
                node {
                    id
                    title
                    path
                    order
                }
            }
        }
    }
`;
