import React from "react";

export default () => (
    <div className="container margin-top-32 margin-bottom-32 padding-24-mobile padding-24-tablet">
        <footer>
            <div className="footer-name">
                <span>
                    MATIERE
                    <br />
                    PREMIERE
                    <br />
                    L'ATELIER
                </span>
            </div>
            <div className="footer-contact">
                <div className="social">
                    <span className="phone">06 45 56 60 92</span>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.instagram.com/charlie_joshua_martel/"
                    >
                        <i className="fab fa-2x fa-instagram" />
                    </a>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/matierepremierelatelier/"
                    >
                        <i className="fab fa-2x fa-facebook" />
                    </a>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.pinterest.fr/charliejmartel/"
                    >
                        <i className="fab fa-2x fa-pinterest" />
                    </a>
                </div>
                <span className="address margin-top-16">
                    372 Rue de la Republique 84220 Goult
                </span>
                <a
                    className="email margin-top-48"
                    href="mailto:matierepremierelatelier@gmail.com"
                >
                    matierepremierelatelier@gmail.com
                </a>
            </div>
        </footer>
    </div>
);
